import cookies from './util.cookies'
import Setting from '@/setting'

const util = {
    cookies
};
/**
 * @description 更改标题
 * @param {Object} title 标题
 */
util.title = function ({ title }) {
  window.document.title = title ? `${title} - ${Setting.titleSuffix}` : Setting.titleSuffix;
};
util.isMobile = function () {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
};

util.truncateText = function (text, len, suffix) {
    if (text.length > len) {
        if (text.codePointAt(len - 1) > 0xFFFF) {
            // 多字节字符
            text = text.substring(0, len - 1);
        } else {
            text = text.substring(0, len);
        }
        if (suffix !== false) {
            if (!suffix) {
                suffix = '...';
            }
        }
        text += suffix;
    }
    return text;
}

/**
 * 图片缩放
 * @param url
 * @param width, height
 * @param outputType png,jpg,jpeg,gif,bmp
 * @param outputQuality 0.1~1
 * @param outputBlob 是否输出blob，默认base64
 * @param cb(data)
 */
util.scaleImage = function (cb, url, width, height, outputType, outputQuality, outputBlob) {
    if (!url || !cb || typeof width !== 'number' || width <= 0 || typeof height !== 'number' || height <= 0) {
        return;
    }
    const img = document.createElement('img');
    img.onload = ()=>{
        const canvas = document.createElement('canvas');
        let ctx = canvas.getContext("2d");
        ctx.save();
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        ctx.restore();

        if (typeof outputType !== 'string') {
            outputType = 'jpg';
        }
        if (typeof outputQuality !== 'number' || outputQuality <= 0 || outputQuality > 1) {
            outputQuality = 1;
        }
        if (outputBlob) {
            canvas.toBlob(cb, 'image/' + outputType, outputQuality);
            return;
        }
        cb(canvas.toDataURL('image/' + outputType, outputQuality));
    };
    img.onerror = ()=>{
        cb(null);
    };
    img.src = url;
}

util.blobToFile = function (blob, fileType, fileName) {
    if (!blob) {
        return null;
    }
    blob.lastModifiedDate = new Date();
    if (typeof fileName !== 'string' || !fileName) {
        if (typeof fileType !== 'string' || !fileType) {
            return null;
        }
        let ext = '';
        if (fileType === 'png' || fileType === 'image/png') {
            ext = '.png';
        } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'image/jpg' || fileType === 'image/jpeg') {
            ext = '.jpg';
        } else if (fileType === 'gif' || fileType === 'image/gif') {
            ext = '.gif';
        } else if (fileType === 'bmp' || fileType === 'image/bmp') {
            ext = '.bmp';
        }
        fileName = blob.lastModifiedDate.getTime() + ext;
    }
    blob.name = fileName;
    return blob;
}
util.base64ImgToFile = function (urlData, fileName) {
    if (urlData.indexOf('data:') !== 0) {
        return null;
    }
    const pos = urlData.indexOf(';base64,');
    if (pos < 0) {
        return null;
    }
    const fileType = urlData.substring(5, pos);
    urlData = urlData.substring(pos + 8);

    const bytes = window.atob(urlData); // 转换为byte
    // 处理异常,将ascii码小于0的转换为大于0
    const ab = new ArrayBuffer(bytes.length);
    const ia = new Int8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    // 转换成文件，添加文件的type，name，lastModifiedDate属性
    const blob = new Blob([ab], { type: fileType });
    if (util.blobToFile(blob, fileType, fileName)) {
        return blob;
    }
    return null;
}

util.showTimeStamp = function (unixTimeStamp, showTime, maxDays) {
    if (!unixTimeStamp) {
        return '';
    }
    if (typeof unixTimeStamp === 'string') {
        const ms = Date.parse(unixTimeStamp);
        if (!ms) {
            return unixTimeStamp;
        }
        unixTimeStamp = Math.floor(ms / 1000);
    } else if (typeof unixTimeStamp !== 'number') {
        return unixTimeStamp;
    }
    if (typeof maxDays !== 'number') {
        maxDays = 3;
    }
    const now = new Date();
    const secs = Math.floor(now.getTime() / 1000) - unixTimeStamp;
    const days = Math.floor(secs / (24 * 60 * 60));
    if (days === 0 && maxDays > 0) {
        const mm = Math.floor(secs / 60) % 60;
        const hh = Math.floor(secs / (60 * 60));
        if (hh > 0) {
            /* if (mm > 0) {
              return hh + '小時' + mm + '分鐘前';
            } */
            return hh + '小時前';
        } else if (mm > 0) {
            return mm + '分鐘前';
        }
        return '剛剛';
    }

    const d = new Date(unixTimeStamp * 1000);
    let timePart = false;
    if (showTime === true) {
        timePart = ' ' + d.getHours() + ':';
        const mm = d.getMinutes();
        if (mm < 10) { timePart += '0'; }
        timePart += mm;
    }
    if (days > maxDays || maxDays === 0) {
        const yy = d.getFullYear();
        let s = '';
        if (yy !== now.getFullYear()) {
            s = yy;
            s += '-';
        }
        const MM = d.getMonth() + 1;
        if (MM < 10) { s += '0'; }
        s += MM;
        s += '-';
        const dd = d.getDate();
        if (dd < 10) { s += '0'; }
        s += dd;

        if (timePart) {
            s += timePart;
        }
        return s;
    }
    let rs;
    if (days === maxDays && maxDays > 2) {
        rs = days + '天前';
    } else if (days === 2) {
        rs = '前天';
    } else {
        rs = '昨天';
    }
    if (timePart) {
        rs += timePart;
    }
    return rs;
}

export default util;
