<template>
  <router-view/>
  <div class="lunch-mask">
    <img src="@/assets/busy.gif" alt=""/>
    <div>{{$store.state.loadingText}}</div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    methods: {
      recordLog (time, pattern, voltage) {
        this.$store.commit('recordLog', { time, pattern, voltage });
      }
    },
    unmounted() {
      this.$omass.stop();
      this.$omass.setRecorder(null);
    },
    mounted() {
      this.$omass.setRecorder(this.recordLog);
    },
    created() {
      // 预加载一些图标
      const icons = ['massage', 'knowledge', 'user'];
      this.cache = [];
      for (let i = 0; i < icons.length; i++) {
        let img = new Image();
        img.src = require('@/assets/' + icons[i] + '.svg');
        this.cache.push(img);
        img = new Image();
        img.src = require('@/assets/' + icons[i] + '-red.svg');
        this.cache.push(img);
      }
    }
  }
</script>

<style scoped lang="scss">
.lunch-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #E71D2C;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  >img {
    margin-bottom: 1vh;
  }
}
</style>
