import axios from 'axios'

export default {
    install (app/*, opts*/) {
        window.fn_extend = function () {
            var length = arguments.length;
            var target = arguments[0] || {};
            if (typeof target!="object" && typeof target != "function") {
                target = {};
            }
            if (length === 1) {
                target = this;
            }
            for (var i = 1; i < length; i++) {
                var source = arguments[i];
                for (var key in source) {
                    // 使用for in会遍历数组所有的可枚举属性，包括原型。
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        target[key] = source[key];
                    }
                }
            }
            return target;
        }
//用于替代$.isFunction
        window.fn_isFunction = function (item) {
            if (typeof item === 'function') {
                return true;
            }
            var type = Object.prototype.toString(item);
            return type === '[object Function]' || type === '[object GeneratorFunction]';
        }

        window.fn_ajax = function (opts) {
            const obj = {
                done: function (func) {
                    this._done = func;
                    return this;
                },
                fail: function (func) {
                    this._fail = func;
                    return this;
                },
                always: function (func) {
                    this._always = func;
                    return this;
                }
            };
            const req = axios.request({
                url: opts.url,
                responseType: opts.dataType
            });
            if (obj._done) {
                req.then(res => {
                    obj._done(res.data, res.statusText, res.request);
                })
            }
            if (obj._fail) {
                req.catch(err => {
                    obj._fail(err.response, err.response ? err.response.statusText : err, err);
                })
            }
            if (obj._always) {
                req.finally(()=>{
                    obj._always();
                })
            }
            return obj;
        }

        window.fn_getJSON = function (url) {
            return window.fn_ajax({
                url: url,
                dataType: "json"
            });
        }

        window.fn_getScript = function (url) {
            const obj = window.fn_ajax({
                url: url,
                dataType: "script"
            }).done(function (script) {
                (window.execScript || function(script) {
                    window['eval'].call(window, script);
                })(script);
                if (this.user_done)
                    this.user_done();
            });
            obj.done = function (func) {
                this.user_done = func;
            }
            return obj;
        }
        const docReadyFuncs = [];
        window._documentReady = function (func) {
            // if (document.readyState !== 'loading') {
            //func();
            // } else {
            //     document.addEventListener('DOMContentLoaded', func);
            // }
            docReadyFuncs.push(func);
        }

        const sdk_path = '/sdk/';
        const scripts = [
            //'jquery_replace.js',
            'oha_api.js',
            'omass_api.js'
        ];
        const init_script = 'oha_mini_app_omass.js';
        const loadJs = (currIndex, callback) => {
            if (currIndex >= scripts.length) {
                // load ok
                for (let i = 0; i < docReadyFuncs.length; i++) {
                    docReadyFuncs[i]();
                }
                callback();
                return;
            }
            const tag = document.createElement('script');// 创建script元素
            tag.src = sdk_path + scripts[currIndex];
            document.head.insertAdjacentElement('afterbegin', tag);
            if (tag.readyState) {
                tag.onreadystatechange = () => {
                    if (tag.readyState === 'loaded' || tag.readyState === 'complete') { // loaded：是否下载完成 complete：js执行完毕
                        console.error(tag.readyState);
                        tag.onreadystatechange = null;
                        loadJs(currIndex+1, callback);
                    }
                }
            } else {
                tag.onload = () => {
                    loadJs(currIndex+1, callback);
                }
            }
        };

        const delegate = {
            api: null,

            mInited: false,
            mIsPlugin: false,

            mStartTime: 0,
            mEndTime: 0,
            mCountdown: -1,
            mTimer: null,

            mPatternId: 1,
            mPatternName: '-',
            mVoltage: 30,
            mDuration: 10 * 60,

            _onPlugState: null,
            _onCountdown: null,
            _onRecord: null,

            isRunning: function () {
                return this.mStartTime > 0;
            },
            isInited: function () {
                return this.mInited;
            },
            init: function (duration, pattern, voltage) {
                if (typeof duration === 'number' && duration >= 30) {
                    this.mDuration = duration;
                }
                if (typeof pattern === 'number' && pattern > 0) {
                    this.mPatternId = pattern;
                }
                if (typeof voltage === 'number' && voltage > 0) {
                    this.mVoltage = voltage;
                }
                this.mInited = true;
            },
            listen: function (onPlugState, onCountdown) {
                this._onPlugState = onPlugState;
                this._onCountdown = onCountdown;
                if (onPlugState) {
                    onPlugState(this.mIsPlugin);
                }
                if (onCountdown) {
                    onCountdown(this.mCountdown);
                }
            },
            setRecorder: function (onRecord) {
                this._onRecord = onRecord;
            },

            setDuration: function (duration) {
                this.mDuration = duration;
                if (this.mIsPlugin) {
                    this.resetTimer();
                }
            },
            getDuration: function () {
                return this.mDuration;
            },
            setPattern: function (id, name) {
                this.mPatternId = id;
                if (!name) {
                    name = ''+id;
                }

                if (this.mStartTime > 0) {
                    this.api.stopPattern();
                    this.api.runPattern(id);
                    this.api.setVoltage(this.mVoltage);
                    // 超过一定时间的中途改变，作新记录
                    const nowTime = new Date().getTime();
                    if (this._onRecord && this.mStartTime + 5 * 1000 < nowTime) {
                        this._onRecord(nowTime);
                        this._onRecord(nowTime, name, this.mVoltage);
                    }
                }
                this.mPatternName = name;
            },
            getPattern: function () {
                return this.mPatternId;
            },
            setVoltage: function (vol) {
                this.mVoltage = vol;
                if (this.mIsPlugin) {
                    this.api.setVoltage(vol);
                }
            },
            getVoltage: function () {
                return this.mVoltage;
            },

            start: function () {
                if (this.mStartTime === 0) {
                    if (this.api && this.mIsPlugin) {//
                        const startTime = this.startTimer();
                        this.api.runPattern(this.mPatternId);
                        this.api.setVoltage(this.mVoltage);
                        this.mStartTime = startTime;
                        if (this._onRecord) {
                            this._onRecord(startTime, this.mPatternName, this.mVoltage);
                        }
                        return true;
                    }
                }
                return false;
            },
            stop: function () {
                if (this.mStartTime > 0) {
                    this.stopTimer();
                    if (this.mIsPlugin && this.api) {
                        this.api.stopPattern();
                    }
                    this.mStartTime = 0;
                    if (this._onRecord) {
                        this._onRecord(new Date().getTime());
                    }
                }
            },
            toggle() {
                if (this.mStartTime > 0) {
                    this.stop();
                    return false;
                }
                return this.start();
            },
            /////////////////////定时器////////////////
            resetTimer: function () {
                const t = this.mDuration;
                this.mCountdown = t;
                if (this._onCountdown) {
                    this._onCountdown(t);
                }
                const startTime = new Date().getTime();
                this.mEndTime = startTime + t * 1000;
                return startTime;
            },
            startTimer: function () {
                this.stopTimer();
                const startTime = this.resetTimer();
                this.mTimer = setInterval(()=>{
                    const t = this.mEndTime - new Date().getTime();
                    if (t < 0) {
                        this.stop();
                    } else {
                        const nt = Math.round(t/1000);
                        if (nt !== this.mCountdown) {
                            this.mCountdown = nt;
                            if (this._onCountdown) {
                                this._onCountdown(nt);
                            }
                        }
                    }
                }, 1000);
                return startTime;
            },
            stopTimer: function () {
                if (this.mTimer) {
                    const timer = this.mTimer;
                    this.mTimer = null;
                    this.mCountdown = -1;
                    clearInterval(timer);
                    if (this._onCountdown) {
                        this._onCountdown(-1);
                    }
                }
            }
        };
        app.config.globalProperties.$omass = delegate;
        loadJs(0, ()=>{
            const api = window.omass_api;
            api.init(sdk_path + init_script);

            api.DevPlugIn = () => {
                api.hideUI();
                if (delegate.mIsPlugin) {
                    return;
                }
                delegate.mIsPlugin = true;
                delegate.mVoltage = api.getVoltage();
                if (delegate._onPlugState) {
                    delegate._onPlugState(true);
                }
            };
            const superDevPlugOut = api.DevPlugOut;
            api.DevPlugOut = () => {
                superDevPlugOut();
                if (!delegate.mIsPlugin) {
                    return;
                }
                delegate.mIsPlugin = false;
                delegate.stop();
                //document.getElementById('logger').style.color='red';
                api.stopPattern();
                //document.getElementById('logger').style.color='black';
                if (delegate._onPlugState) {
                    delegate._onPlugState(false);
                }
            };
            delegate.api = api;
        });
    }
}
