import request from '@/libs/request'

// 地区码
export function States () {
    return request({
        url: '/states',
        method: 'get',
        ex: { ignores: [] }
    })
}

export function SmsCode (data) {
    return request({
        url: '/v1/sms/send',
        method: 'post',
        data
    })
}
// 校验码
export function VerifyCode () {
    return request({
        url: '/captcha/json',
        method: 'get'
    })
}

export function UserRegister (data) {
    return request({
        url: '/v1/user/register',
        method: 'post',
        data
    })
}

export function UserLogin (data) {
    return request({
        url: '/v1/user/login',
        method: 'post',
        data
    })
}

export function SwapToken (data) {
    return request({
        url: '/v1/mall/autologin',
        method: 'post',
        data
    })
}

export function UserInfo () {
    return request({
        url: '/v1/user',
        method: 'get',
        ex: {
            ignores: []
            // ignores: 2000100
        }
    })
}

export function ChangePwd (oldpassword, password) {
    return request({
        url: '/v1/user/password',
        method: 'post',
        data: {
            oldpassword,
            password
        }
    })
}
// 验证短信码
export function VerifySmsCode (stateCode, mobile, code) {
    return request({
        url: '/v1/user/verifyCode',
        method: 'post',
        data: {
            state_code: stateCode,
            mobile,
            code
        }
    })
}

export function ResetPwd (data) {
    return request({
        url: '/v1/user/resetPwd',
        method: 'post',
        data
    })
}

export function ModifyUser (data) {
    return request({
        url: '/v1/user',
        method: 'put',
        data
    })
}
