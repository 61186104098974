import { createRouter, createWebHistory } from 'vue-router'
import util from '@/libs/util'
import i18n0 from '@/i18n'
import store from '@/store'
import Home from '@/views/Home.vue'

const i18n = i18n0.global;
const routes = [
  {
    path: '/error',
    name: 'Error',
    meta: {
      title: i18n.t('page.error.title'),
      noLogin: true
    },
    component: () => import('@/views/PageError.vue')
  },
  {
    path: '/',
    name: 'PageHome',
    meta: {
      login: '/massage',
      default: '/entry'
    },
    component: Home,
    children: [
      {
        path: '/entry',
        name: 'PageEntry',
        meta: {
          title: '',
          noLogin: true
        },
        component: () => import('@/views/Entry.vue')
      },
      {
        path: '/login',
        name: 'PageLogin',
        meta: {
          title: i18n.t('page.login.title'),
          isLogin: true
        },
        component: () => import('@/views/Login.vue')
      },
      {
        path: '/register',
        name: 'PageRegister',
        meta: {
          title: i18n.t('page.reg.title'),
          isLogin: true
        },
        component: () => import('@/views/Register.vue')
      },
      {
        path: '/forget',
        name: 'ResetPwd',
        meta: {
          title: i18n.t('page.resetpwd.title'),
          noLogin: true
        },
        component: () => import('@/views/ResetPwd.vue')
      }
    ]
  },
  {
    path: '/massage',
    name: 'PageMassage',
    meta: {
      title: i18n.t('page.massage.title'),
      noLogin: true
    },
    component: () => import('@/views/PageMassage.vue')
  },
  {
    path: '/knowledge',
    name: 'PageKnowledge',
    meta: {
      title: i18n.t('page.knowledge.title'),
      noLogin: true
    },
    component: () => import('@/views/PageKnowledge.vue')
  },
  {
    path: '/article',
    name: 'PageArticle',
    meta: {
      title: i18n.t('page.knowledge.title'),
      noLogin: true
    },
    component: () => import('@/views/PageArticle.vue')
  },
  {
    path: '/user',
    name: 'PageUser',
    meta: {
      title: '',
      noLogin: true
    },
    component: () => import('@/views/PageUser.vue')
  },
  {
    path: '/userinfo',
    name: 'UserInfo',
    meta: {
      title: i18n.t('page.user.item_userinfo'),
    },
    component: () => import('@/views/UserInfo.vue')
  },
  {
    path: '/password',
    name: 'UserPwd',
    meta: {
      title: i18n.t('page.user.item_pwd'),
    },
    component: () => import('@/views/UserPwd.vue')
  },
  {
    path: '/record',
    name: 'UserRecord',
    meta: {
      title: i18n.t('page.user.item_record'),
    },
    component: () => import('@/views/UserRecord.vue')
  },
  {
    path: '/setup',
    name: 'UserAppDev',
    meta: {
      title: i18n.t('page.user.item_set_up'),
    },
    component: () => import('@/views/UserAppDev.vue')
  },
  {
    path: '/product',
    name: 'AboutProduct',
    meta: {
      title: i18n.t('page.user.item_product'),
      noLogin: true
    },
    component: () => import('@/views/AboutProduct.vue')
  },
  {
    path: '/privacy',
    name: 'ServicePrivacy',
    meta: {
      title: i18n.t('page.user.item_terms'),
      noLogin: true
    },
    component: () => import('@/views/ServicePrivacy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const errorHolder = function (err) {
  return {
    name: 'Error',
    params: err,
    query: { t: Math.random() },
  };
};

const loginHolder = {
  busy: false,
  should: false,
  route: {
    name: 'PageLogin',
    params: {}
  },
  register: 'PageRegister',
  defaultJump: 'PageMassage',
  next: null
};

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    if (to.name === errorHolder.name) {
      return;
    }
    next(errorHolder({ code: 404, message: i18n.t('error.404')}));
    return;
  }
  if (to.name === errorHolder.name) {
    next();
    return;
  }

  let loginTarget = to;
  if (to.meta.isLogin) {
    // 进入登录注册界面的参数设置
    if (from.meta.isLogin) {
      loginTarget = from.params.target;
    } else if (from.name) {
      loginTarget = from;
    } else {
      loginTarget = '/';
    }
  }
  loginHolder.route.params.target = loginTarget;
  loginHolder.should = !to.meta.isLogin && !to.meta.noLogin;
  loginHolder.next = next;
  // 可选登录
  if (!to.meta.isLogin && to.name === Home.name) {
    const orgNext = next;
    if (to.meta.default) {
      next = () => {
        orgNext(to.meta.default);
      }
    }
    if (to.meta.login) {
      loginHolder.next = () => {
        orgNext(to.meta.login);
      }
    }
  }

  // 从url中获取token，通常是扫码
  let token = to.query.token;
  if (token) {
    delete to.query.token;
    store.commit('setLoading', i18n.t('common.logining'));
    store.dispatch('swapToken', { token }).then(() => {
      // 用以标记
      to.params.autoLogin = true;
      next();
    }).catch(() => {
      next(loginHolder.route);
    });
    return;
  }

  if (to.meta.isLogin) {
    // 进入登录注册界面的参数设置
    to.params.target = loginTarget;
    next();
    return;
  }

  if (!store.state.userinfo) {
    // 在每个页面都尝试登录
    if (!loginHolder.busy || loginHolder.should) {
      token = util.cookies.get('token');
      if (!token) {
        // 没有token或token失效
        if (loginHolder.should) {
          if (!loginHolder.busy) {
            // 直接跳转到登录页
            next(loginHolder.route);
          }
          return;
        }
      } else {
        // 尝试获取用户信息
        loginHolder.busy = true;
        store.commit('setLoading', i18n.t('common.logining'));
        let error = null;
        store.dispatch('load').catch((err) => {
          error = err;
        }).finally(() => {
          loginHolder.busy = false;
          if (!loginHolder.should) {
            return;
          }
          if (error) {
            switch (error.code) {
              case 1011000:// 令牌失效
              case 1020025:// 授权過期
                util.cookies.remove('token');
                break;
            }
            // 不是什么错误都要跳到登录
            switch (error.code) {
              case 401:
              case 1010000:// 未登录
              case 1011000:// 令牌失效
              case 1020004:// 帐号未注册
              case 1020025:// 授权過期
              case 2000100:
                next(loginHolder.route);
                break;
              default:
                next(errorHolder(error));
            }
          } else {
            // 登录成功
            loginHolder.next();
          }
        });
        if (loginHolder.should) {
          return;
        }
      }
    }
  }
  next();
});

router.afterEach(to => {
  if (router.clean) {
    router.clean();
  }
  if (window.ga) {
    window.ga('set', 'page', to.fullPath) // 你可能想根据请求参数添加其他参数，可以修改这里的 to.fullPath
    window.ga('send', 'pageview')
  }
  // 更改标题
  util.title({
    title: to.meta.title
  });
  // 返回页面顶端
  window.scrollTo(0, 0);
  store.commit('setLoading', null);
});

// 常用跳转
router.face = {
  showError: (arg0, arg1) => {
    const err = {code: 0, message: ''};
    if (arg1 === undefined) {
      err.code = 600;
      err.message = arg0;
    } else {
      err.code = arg0;
      err.message = arg1;
    }
    router.replace(errorHolder(err));
  },
  gotoOfficial: () => {
    window.location.href = 'https://www.tvsoga.com/';
  },
  // 是否登录
  hasLogin () {
    return !!store.state.userinfo && !!util.cookies.get('token');
  },
  // 产生登录的route
  genLogin: (register, backTo) => {
    const route = {
      name: register ? loginHolder.register : loginHolder.route.name,
      params: {}
    }
    if (backTo) {
      route.params.target = backTo;
    } else {
      // 默认登录后跳转
      const curr = router.currentRoute.value;
      let target = curr;
      if (curr.meta && curr.meta.isLogin) {
        // 使用携带参数
        target = curr.params.target;
        if (!target) {
          target = curr.params.from;
        }
        if (target) {
          target = router.resolve(route);
        }
        if (!target || (target.meta && target.meta.isLogin)) {
          target = router.resolve({name:loginHolder.defaultJump});
        }
      }
      route.params.target = target;
    }
    return route;
  },
  login: (register, backTo, next) => {
    const route = router.face.genLogin(register, backTo);
    if (next) {
      next(route);
    } else {
      // const meta = router.currentRoute.meta;
      // if (meta && meta.isLogin) {
      router.replace(route);
      // } else {
      //   router.push(route);
      // }
    }
  },
  logout: () => {
    store.commit('logout');
    const meta = router.currentRoute.value.meta;
    if (meta && !meta.noLogin/* && !meta.isLogin */) {
      // 在需要登录的界面才自动跳转到登录
      window.location.replace({ name: loginHolder.route.name });
      return false;
    }
    return true;
  },
  loginJump: () => {
    // const curr = router.currentRoute.value;
    router.replace({name:loginHolder.defaultJump});
  }
};

export default router
