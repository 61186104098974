<template>
  <div class="page-home">
    <div class="btn-back" v-if="$route.name!=='PageEntry'"
         style="position: absolute;left: 3vw;top:3vw;" @click.stop="onBack"></div>
    <div class="logo">LOGO</div>
    <div class="slogan">tvSOGA</div>
    <div class="container-area">
      <router-view/>
    </div>
    <div class="footer">版本 v{{ version }}</div>
  </div>
</template>

<script>
import config from '../../package.json'

export default {
  name: 'PageHome',
  data() {
    return {
      version: config.version
    };
  },
  methods: {
    onBack () {
      if (this.$route.meta.isLogin) {
        if (this.$route.params.target) {
          this.$router.replace(this.$route.params.target);
        } else {
          this.$router.replace('/');
        }
        return;
      }
      this.$router.back();
    }
  }
}
</script>

<style scoped lang="scss">
.page-home {
  background-color: #E71D2C;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  -webkit-overflow-scrolling:touch;
  overflow-y: auto;
  .logo {
    margin-top: 10vh;
    margin-bottom: 2vh;
    border-radius: 50%;
    box-sizing: border-box;
    border: white 1px solid;
    width: 30vw;
    height: 30vw;

    line-height: 30vw;
    text-align: center;
    vertical-align: middle;
    color: white;
  }
  .slogan {
    border: white 1px solid;
    color:white;
    line-height: 8vw;
    width: 70%;
    text-align: center;
  }
  .container-area {
    flex: 1 1 auto;
    margin: 4.5vh 0;
    width: 80%;
    min-height: calc(4 * min(15vw, 17vh));
    box-sizing: border-box;
    padding: 2vw;
  }
  .footer {
    color: white;
    margin-top: 2vw;
    margin-bottom: 8vw;
  }
}
</style>
