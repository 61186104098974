import { createI18n } from 'vue-i18n/index';
import Setting from '@/setting'
import store from '@/store'

// 导入自有语言包
import zhCN from './locale/zh-CN';
// import enUS from './locale/en-US';
import zhTW from './locale/zh-TW'

if (!store.state.locale && !Setting.i18n.auto) {
    store.commit('setLocale', Setting.i18n.default);
}

// 使用浏览器语言
const lang = (store.state.locale || navigator.language || navigator.browserLanguage).toLowerCase();
let locale;
if (lang === 'zh-cn') {
    locale = zhCN.locale;
} else {
    locale = zhTW.locale;
}
store.commit('setLocale', locale);
const abc = createI18n({
    globalInjection: true, // 全局注册 $t方法
    locale,
    messages: {
        'zh-CN': zhCN,
//'en-US': enUS,
        'zh-TW': zhTW
    }
});
export default abc;
