/**
 * iView Admin Pro 业务配置
 * */

// import { Message, Notice } from 'view-design'

const env = process.env.NODE_ENV

const serverList = {
  develop_server: 'https://omass.atlasai.tv',
  product_server: 'https://omass.atlasai.tv',
  local_server: 'https://bw.huangyuqiang.cn'
}

const Setting = {
  /**
     * 基础配置
     * */
  // 网页标题的后缀
  titleSuffix: 'tv.SOGA',
  // 路由模式，可选值为 history 或 hash
  routerMode: 'history',
  // 应用的基路径
  routerBase: '/',
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,
  // 服务器配置
  serverList: serverList,
  // 接口请求地址
  apiBaseURL: env === 'development' ? serverList.develop_server : serverList.product_server,
  // 接口请求超时时间，单位：秒
  apiTimeout: 20,

  // 接口请求返回错误时，弹窗的持续时间，单位：秒
  modalDuration: 3,
  // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
  errorModalType: 'Notice',
  // 错误提示弹窗标题
  errorModalTitle: 'Alert',
  // 错误提示弹窗标题
  errorDescriptions: {},
  // 错误显示
  showError: (/*message, code*/) => {},

  appBridge: () => {
    return window && window.HTMLOBJ
  },
  // Cookies 默认保存时间，单位：天
  cookiesExpireDays: 30,
  cookiesPrefix: 'soga_omass',
  /**
    * 多语言配置
    * */
  i18n: {
    // 默认语言
    default: 'zh-TW',
    // 是否根据用户电脑配置自动设置语言（仅第一次有效）
    auto: true
  },
  /**
     * 多页 Tabs
     * */
  page: {
    // 默认打开的页签
    opened: []
  },
  languages: {
    'zh-TW': '繁體中文',
    'zh-CN': '简体中文',
    en: 'En'
  }
}

export default Setting
