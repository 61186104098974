export default {
    locale: 'zh-CN',
    language: '简体中文',
    common: {
        alert_title: '系统提示',
        btn_ok: '确定',
        btn_cancel: '取消',
        btn_back: '返回',
        btn_save: '保存',
        btn_del: '删除',
        err_param: '参数错误',

        tip_save_ok: '保存成功！',
        tip_modify_ok: '修改成功！',
        logining: '正在登录',

        jump_index: '首页',
        jump_to_official:'跳转到',
        jump_official:'官网'
    },
    error: {
        400: '请求错误',
        401: '未授权，请登录',
        403: '拒绝访问',
        404: '请求错误',
        408: '请求超时',
        500: '服务器内部错误',
        501: '服务器内部错误',
        502: '网关错误',
        503: '服务不可用',
        504: '网关超时',
        505: 'HTTP版本不受支持',

        1000000: '获取数据失败！',
        1010000: '未登录！',
        1011000: '令牌失效！',
        1012000: '无权访问！',

        // 1020000: '参数错误！',
        1020003: '密码格式不正确！',
        1020004: '帐号未注册！',
        1020005: '密码错误！',

        1020014: '验证码错误！',
        1020015: '邮箱地址错误！',
        1020022: 'SN碼错误！',
        1020023: 'SN已經被使用！',
        1020024: '没有绑定SN！',
        1020025: '授权過期！',

        1021000: '文件上传错误！',
        1021002: '文件不存在！',

        1100000: '服务器异常，请重试！',
        1100001: '操作超时！',

        1200000: '服务器维护中！'
    },
    tips: {
        login: '该功能仅提供会员使用，请注册或登录本公司会员帐号，即可使用该服务功能。',
        btn_next_time: '下次再说',
        reg_or_login: '注册/登录'
    },
    page: {
        title: 'tv.SOGA电疗中心',
        error: {
            title: '对不起，出错了！'
        },
        reg: {
            title: '我要注册',
            btn_reg: '注册',
            btn_login: '我要登录',
            ph_pwd: '6-15位字母或数字',
            ph_pwd_confirm: '再次输入设定密码',
            diff_pwd: '两次密码输入不一致！',

            ph_code: '验证码',
            verify_code: '输入校验码',
            invalid_code: '校验码不正确！',
            btn_code: '获取短信验证码',
            invalid_sms: '短信验证码不正确！',

            label_agree: '已阅读并同意',
            privacy_title: '隐私保护条款',
            tip_privacy: '需要同意隐私保护条款！',
            btn_agree: '同意',
            btn_disagree: '不同意',

            register_ok: '欢迎您加入tv.SOGA！'
        },
        login: {
            title: '我要登录',
            forgot: '忘记密码?',
            btn_login: '登录',
            btn_reg: '我要注册',
            ph_statecode: '请选择区号！',
            ph_mobile: '请填写手机号码！',
            ph_pwd: '请输入密码！',
            tip_invalid_pwd: '密码无效！',

            quick_opt: '操作过于频繁！',
            login_ok: '欢迎您再次回来！',
            login_fail: '登录失败！'
        },
        resetpwd: {
            title: '重置密码',
            btn_reset: '重置密码',

            reset_ok: '密码重置成功！'
        },
        userinfo: {
            mobile: '手机号码',
            nick: '昵称',
            sex: '性别',
            sex_male: '男',
            sex_female: '女',

            height: '身高',
            height_unit: '厘米',
            weight: '体重',
            weight_unit: '公斤',

            birth_year: '出生年份',
            ph_birth: '填写公元年',

            ph_unset: '未设定',
            ph_number: '请填写数字',

            photo_title: '会员头像修改',
            btn_photo_upload: '上传照片'
        },
        setpwd: {
            old_pwd: '旧密码',
            new_pwd: '新密码',
            cfm_pwd: '确认密码',
            ph_old_pwd: '请输入旧密码',
            ph_new_pwd: '6-15位字母或数字',
            ph_cfm_pwd: '再次输入设定密码',
            diff_pwd: '两次密码输入不一致！',
            invalid_pwd: '新密码无效！',

        },
        massage: {
            title: '按摩放松',
            time: '按摩时间',
            strength: '按摩力道',
            pattern: '模式',
            start: '开始按摩',
            stop: '停止按摩',

            pattern_method: '按摩手法',
            pattern_part: '按摩部位',

            pattern1: '按压',
            pattern2: '揉捏',
            pattern3: '捶打',
            pattern4: '肩部',
            pattern5: '手臂',
            pattern6: '腰部',
            pattern7: '腿部',
            pattern8: '全部',
        },
        knowledge: {
            title: '中医知识',
            ph_search: '请输入关键字',
            category_all: '全部',
            massage: '进入按摩',
            recommend: '文章推荐'
        },
        user: {
            unlogin: '尚未登录',

            item_userinfo:'个人资料修改',
            item_pwd:'密码修改',
            item_record:'按摩记录',
            item_set_up:'应用程序与设备',
            item_store:'产品购买',
            item_product:'关于产品',
            item_terms:'服务条款',
            item_login:'注册/登录',
            item_logout:'登出',
        },
        record: {
            no_data: '没有记录！',
            pattern: '模式：',
            duration: '时间：',
            last_pattern: '上次按摩模式：',
            last_duration: '上次按摩时间：',
            total_duration: '总时长：'
        }
    }
}
