import request, { sync } from '@/libs/request'
import axios from 'axios';

export const httpPost = (url, data, cancelToken, ex) => {
  return request({
    url,
    data,
    method: 'post',
    cancelToken,
    ex
  })
}

export const httpGet = (url, cancelToken, ex) => {
  return request({
    url,
    method: 'get',
    cancelToken,
    ex
  })
}

export const httpGetParams = (url, params, cancelToken, ex) => {
  return request({
    url,
    method: 'get',
    params,
    cancelToken,
    ex
  })
}

export const httpPostFile = (url, data, cancelToken, ex) => {
  return request({
    url,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
    method: 'post',
    cancelToken,
    ex
  })
}

export const httpPostFileProgress = (url, data, cancelToken, onUploadProgress, ex) => {
  return request({
    url,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
    onUploadProgress,
    method: 'post',
    cancelToken,
    ex
  })
}

export const httpDelete = (url, data, cancelToken, ex) => {
  return request({
    url,
    data,
    method: 'delete',
    cancelToken,
    ex
  })
}

export const httpPut = (url, data, cancelToken, ex) => {
  return request({
    url,
    data,
    method: 'put',
    cancelToken,
    ex
  })
}

export const httpSync = (requests, callback) => {
  return sync(requests, callback);
}

export const CancelToken = axios.CancelToken
