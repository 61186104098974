import { createStore } from 'vuex'
import cookies from '@/libs/util.cookies'
import request from '@/libs/request'
import { States, UserRegister, UserLogin, SwapToken, UserInfo, ModifyUser } from '@/api/account'
import {httpPost} from "@/api/http";

function onLogin (state, res) {
  const expire = new Date();
  state.loginTime = expire.getTime();
  expire.setTime(res.expires_in * 1000);
  const setting = {
    expires: expire
  };
  cookies.set('token', res.access_token, setting)
  cookies.set('token-expires', res.expires_in, setting)
  cookies.set('token-type', res.token_type, setting);
}

function loadLocalRecord (state) {
  if (!state.userinfo) {
    return;
  }
  state.mRecordList = JSON.parse(window.localStorage.getItem('record-cache-'+state.userinfo.id));
  if (!state.mRecordList) {
    state.mRecordList = [];
  }
}

function saveLocalRecord (state) {
  if (!state.userinfo) {
    return;
  }
  if (state.mRecordList.length > 0) {
    window.localStorage.setItem('record-cache-'+state.userinfo.id, JSON.stringify(state.mRecordList));
  } else {
    window.localStorage.removeItem('record-cache-'+state.userinfo.id);
  }
}

function submitRecord(state, nowTime) {
  if (state.mRecordList.length === 0) {
    return;
  }
  if (state.mLogging) {
    return;
  }
  if (!nowTime) {
    nowTime = Math.floor(new Date().getTime() / 1000);
  }
  state.mLogging = true;
  httpPost('/v1/use/logs', state.mRecordList).then(()=>{
    state.mRecordList.splice(0);
  }).catch(()=>{
    for (let i = 0; i < state.mRecordList.length; i++) {
      state.mRecordList[i].retry++;
    }
  }).finally(()=>{
    saveLocalRecord(state);
    state.mLogging = false;
    state.mSubmitTime = nowTime;
  });
}

export default createStore({
  state: {
    userinfo: null,
    headImg: null,
    local_info: JSON.parse(window.localStorage.getItem('login_info')),
    loadingText: 'Loading...',
    caches: {},
    locale: '',
    // 地区码
    states: null,
    areas: null,

    massage: null,

    mRecordList: null,
    mRecord: null,
    mSubmitTime: 0,
    mLogging: false,
  },
  getters: {
    states (state) {
      if (state.states) {
        return state.states;
      }
      return JSON.parse(window.localStorage.getItem('states'));
    },
    userHead (state) {
      if (state.headImg) {
        return state.headImg;
      }
      const user = state.userinfo;
      if (state.headImg === null && user && user.head) {
        state.headImg = user.head;
        return state.headImg;
      }
      let src = require('@/assets/unknown.png');
      if (user) {
        switch (user.sex) {
          case 0:
            src = require('@/assets/male.png');
            break;
          case 1:
            src = require('@/assets/female.png');
            break;
        }
      }
      state.headImg = src;
      return src;
    }
  },
  mutations: {
    setLoading (state, text) {
      state.loadingText = text;
    },
    setLocale (state, locale) {
      state.locale = locale;
    },
    setLocalInfo (state, data) {
      state.local_info = data;
      window.localStorage.setItem('login_info', JSON.stringify(data));
    },
    caches (state, {key, value}) {
      if (value === undefined) {
        delete state.caches[key];
      } else {
        state.caches[key] = value;
      }
    },
    logout (state) {
      cookies.remove('token');
      cookies.remove('token-expires');
      cookies.remove('token-type');
      state.userinfo = null;
      state.headImg = null;
      state.loginTime = 0;
    },
    setHead (state, head) {
      if (state.userinfo) {
        state.userinfo.head = head;
        if (head !== state.headImg) {
          state.headImg = null;
        }
      }
    },

    ///////////////massage//////////////
    setMassage(state, data) {
      if (data && state.massage) {
        for (const i in data) {
          state.massage[i] = data[i];
        }
      } else {
        state.massage = data;
      }
      state.massage = data;
      window.localStorage.setItem('massage', data ? JSON.stringify(data) : null);
    },
    recordLog (state, { time, pattern, voltage }) {
      time = Math.floor(time / 1000);
      if (pattern || voltage) {
        // 不论是否登录，先记下来。
        state.mRecord = {start_time:time, pattern};
      } else if (state.mRecord){
        const record = state.mRecord;
        state.mRecord = null;
        if (!state.userinfo) {
          return;
        }
        record.duration = time - record.start_time;
        record.retry = 0;
        if (record.duration < 5) {
          state.mRecordList.push(record);
          saveLocalRecord(state);
        }

        //间隔60s内不提交数据
        if (state.mSubmitTime + 60 < time) {
          submitRecord(state, time);
        }
      }
    }
  },
  actions: {
    // 获取国际区域码
    loadStates ({ state }) {
      return new Promise((resolve, reject) => {
        States().then(async res => {
          state.states = res.detail;
          window.localStorage.setItem('states', JSON.stringify(res.detail));
          resolve(res.detail)
        }).catch(err => {
          // console.log('err: ', err);
          reject(err)
        });
      })
    },
    // 获取地区码
    loadAreas ({ state }) {
      return new Promise((resolve, reject) => {
        request({
          url: '/areas',
          method: 'get'
        }).then(async res => {
          state.areas = res;
          resolve(res);
        }).catch(err => {
          // console.log('err: ', err);
          reject(err)
        });
      })
    },
    register ({ state, dispatch }, {
      stateCode,
      mobile,
      password,
      code
    } = {}) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        UserRegister({
          state_code: stateCode,
          mobile,
          password,
          code,
          trial: 1
        }).then(async res => {
          onLogin(state, res)
          await dispatch('load')
          // 结束
          resolve()
        }).catch(err => {
          // console.log('err: ', err);
          reject(err)
        });
      })
    },
    /**
     * @description 登录
     * @param {Object} dispatch
     * @param stateCode 国家码
     * @param mobile 手机号
     * @param password 密码
     */
    login ({ state, dispatch }, {
      stateCode,
      mobile,
      password
    } = {}) {
      return new Promise((resolve, reject) => {
        // 开始请求登录接口
        UserLogin({
          state_code: stateCode,
          mobile,
          password
        }).then(async res => {
          onLogin(state, res)
          // 设置 vuex 用户信息
          // 用户登录后从持久化数据加载一系列的设置
          await dispatch('load')
          // 结束
          resolve()
        }).catch(err => {
          // console.log('err: ', err);
          reject(err)
        })
      })
    },

    swapToken ({ state, dispatch }, { token }) {
      return new Promise((resolve, reject) => {
        // console.log('请求：token', token)
        // 开始请求登录接口
        SwapToken({
          token
        }).then(async res => {
          onLogin(state, res)
          // 设置 vuex 用户信息
          // 用户登录后从持久化数据加载一系列的设置
          await dispatch('load')
          // 结束
          resolve()
        }).catch(err => {
          // console.log('err: ', err);
          reject(err)
        })
      })
    },

    /**
     * @description 用户登录后从持久化数据加载一系列的设置
     * @param {Object} state vuex state
     * @param {Object} dispatch vuex dispatch
     */
    load ({ state/*, dispatch*/ }) {
      return new Promise((resolve, reject) => {
        UserInfo().then(async res => {
          // 设置 vuex 用户信息
          state.userinfo = res;
          state.headImg = null;

          loadLocalRecord(state);
          if (state.mRecordList.length > 0) {
            submitRecord(state);
          }
            // 结束
          resolve();
        }).catch(err => {
          // console.log('err: ', err);
          reject(err);
        })
      })
    },
    modifyUser ({ state/*, dispatch*/ }, user) {
      return new Promise((resolve, reject) => {
        ModifyUser(user).then(async res => {
          // 更新用户信息
          state.userinfo.nick = res.nick;
          state.userinfo.realname = res.realname;
          state.userinfo.birthday = res.birthday;
          state.userinfo.sex = res.sex;
          state.userinfo.areas = res.areas;
          resolve(state.userinfo);
        }).catch(err => {
          // console.log('err: ', err);
          reject(err);
        })
      })
    }
  },
  modules: {
  }
})
