const Message = {
    _el: null,

    show: function (info) {
        const defDuration = 30000;
        if (typeof info == 'string') {
            info = {
                content: info,
                duration: defDuration
            }
        } else if (info && info.content) {
            if (typeof info.duration == 'string') {
                info.duration = parseInt(info.duration);
            }
            if (typeof info.duration != 'number' || info.duration < 1000 || info.duration > 60 * 1000) {
                info.duration = defDuration;
            }
        } else {
            info = {
                content: ''+info,
                duration: defDuration
            }
            return;
        }
        if (!this._el) {
            this._el = document.createElement('div');
            const style = this._el.style;
            style.pointerEvents = 'none';
            style.zIndex = 900;
            style.display = 'flex';
            style.flexDirection = 'column';
            style.alignItems = 'center';
            //style.justifyContent = 'center';
            style.position = 'fixed';
            style.left = '0';
            style.top = '5vh';
            style.width = '100vw';
            style.height = '100vh';
        }
        if (!this._el.parentNode) {
            document.body.append(this._el);
        }

        const box = document.createElement('div');
        box.style.position = 'relative';
        box.style.pointerEvents = 'auto';
        box.style.marginBottom = '1vh';
        box.style.boxSizing = 'border-box';
        box.style.minWidth = 'min(35vw, 35vh)';
        box.style.minHeight = '6vh';
        box.style.maxWidth = '80vw';
        box.style.maxHeight = '50vh';
        box.style.width = 'fit-content';
        //box.style.height = 'fit-content';
        box.style.borderRadius = '5px';

        const bg = document.createElement('div');
        bg.style.width = '100%';
        bg.style.height = '100%';
        bg.style.backgroundColor = 'white';
        bg.style.border = '1px gray solid';
        bg.style.borderRadius = box.style.borderRadius;
        bg.style.boxSizing = 'border-box';
        bg.style.opacity = '0.5';

        bg.style.color = bg.style.backgroundColor;
        box.append(bg);

        const fillText = (el) => {
            el.style.fontSize = 'min(4vw, 6vh)';
            el.style.padding = box.style.borderRadius;
            el.style.lineHeight = box.style.minHeight;
            el.style.wordBreak = 'break-all';
            el.style.verticalAlign = 'middle';
            el.style.textAlign = 'center';
            el.innerText = info.content;
        }

        const tv = document.createElement('div');
        tv.style.position = 'absolute';
        tv.style.top = '0';
        tv.style.width = '100%';
        tv.style.boxSizing = 'border-box';
        tv.style.borderRadius = box.style.borderRadius;
        box.append(tv);

        fillText(bg);
        fillText(tv);

        this._el.append(box);
        if (this._el.childNodes.length > 5) {
            this._el.removeChild(this._el.childNodes[0]);
        }

        setTimeout(()=>{
            if (box.parentNode)
                box.parentNode.removeChild(box);
            if (this._el.childNodes.length === 0) {
                const parent = this._el.parentNode;
                if (parent) {
                    parent.removeChild(this._el);
                }
            }
        }, info.duration);
    },
    hide: function () {
        if (this._el) {
            while (this._el.firstChild)
                this._el.removeChild(this._el.firstChild);
            if (this._el.parentNode)
                this._el.parentNode.removeChild(this._el);
        }
    }
};
Message.error = Message.show;

const Spin = {
    _el: null,
    _tv: null,
    show: function (info) {
        if (!this._el) {
            this._el = document.createElement('div');
            const style = this._el.style;
            style.zIndex = 1000;
            style.position = 'fixed';
            style.left = '0';style.top = '0';
            style.width = '100vw';style.height = '100vh';

            const bg = document.createElement('div');
            bg.style.position = 'absolute';
            bg.style.left = '0';
            bg.style.top = '0';
            bg.style.width = '100%';
            bg.style.height = '100%';
            bg.style.backgroundColor='black';
            bg.style.opacity = '0.3';
            this._el.append(bg);

            const box = document.createElement('div');
            box.style.marginTop = '40vh';
            box.style.width = 'fit-content';
            box.style.marginLeft = 'auto';
            box.style.marginRight = 'auto';
            const img = document.createElement('img');
            img.src = require('@/assets/busy.gif');
            img.style.display = 'block';
            img.style.marginLeft = 'auto';
            img.style.marginRight = 'auto';
            img.style.opacity = 0.99;
            box.append(img);
            this._tv = document.createElement('div');
            this._tv.style.lineHeight = '32px';
            box.append(this._tv);

            this._el.append(box);
        }
        if (!this._el.parentNode) {
            document.body.append(this._el);
        }
        this._tv.innerText = info ? info : '';
    },
    hide: function () {
        if (this._el && this._el.parentNode)
            this._el.parentNode.removeChild(this._el);
    }
};

const Notice = {
    show: function (info) {
        if (typeof info == 'object') {
            if (info.desc) {
                info.content = info.desc;
            }
        }
        Message.show(info);
    }
};
Notice.error = Notice.show;
export default {
    Message,
    Notice,
    Spin,
    install (app/*, opts*/) {
        app.config.globalProperties.$Message = {
            show: Message.show,
            error: Message.show
        }
        app.config.globalProperties.$Spin = {
          show: Spin.show,
          hide: Spin.hide
        };
        app.config.globalProperties.$router.clean = () => {
            Message.hide();
            Spin.hide();
        }
    }
}