export default {
    locale: 'zh-TW',
    language: '繁體中文',
    common: {
        alert_title: '系統提示',
        btn_ok: '確定',
        btn_cancel: '取消',
        btn_back: '返回',
        btn_save: '保存',
        btn_del: '刪除',
        err_param: '參數錯誤',

        tip_save_ok: '保存成功！',
        tip_modify_ok: '修改成功！',
        logining: '正在登錄',

        jump_index: '首頁',
        jump_to_official:'跳轉到',
        jump_official:'官網'
    },
    error: {
        400: '請求錯誤',
        401: '未授權，請登錄',
        403: '拒絕訪問',
        404: '請求錯誤',
        408: '請求超時',
        500: '服務器內部錯誤',
        501: '服務器內部錯誤',
        502: '網關錯誤',
        503: '服務不可用',
        504: '網關超時',
        505: 'HTTP版本不受支持',

        1000000: '獲取數據失敗！',
        1010000: '未登錄！',
        1011000: '令牌失效！',
        1012000: '無權訪問！',

        // 1020000: '參數錯誤！',
        1020003: '密碼格式不正確！',
        1020004: '帳號未註冊！',
        1020005: '密碼錯誤！',

        1020014: '驗證碼錯誤！',
        1020015: '郵箱地址錯誤！',
        1020022: 'SN碼錯誤！',
        1020023: 'SN已經被使用！',
        1020024: '沒有綁定SN！',
        1020025: '授權過期！',

        1021000: '文件上傳錯誤！',
        1021002: '文件不存在！',

        1100000: '服務器異常，請重試！',
        1100001: '操作超時！',

        1200000: '服務器維護中！'
    },
    tips: {
        login: '該功能僅提供會員使用，請註冊或登入本公司會員帳號，即可使用該服務功能。',
        btn_next_time: '下次再說',
        reg_or_login: '註冊/登入'
    },
    page: {
        title: 'tv.SOGA電療中心',
        error: {
            title: '對不起，出錯了！'
        },
        reg: {
            title: '我要註冊',
            btn_reg: '註冊',
            btn_login: '會員登入',
            ph_pwd: '6-15位字母或數字',
            ph_pwd_confirm: '再次輸入設定密碼',
            diff_pwd: '兩次密碼輸入不一致！',

            ph_code: '驗證碼',
            verify_code: '輸入校驗碼',
            invalid_code: '校驗碼不正確！',
            btn_code: '傳送手機驗證碼',
            invalid_sms: '短信驗證碼不正確！',

            label_agree: '已閱讀並同意',
            privacy_title: '隱私權保護宣告',
            tip_privacy: '需要同意隱私權保護宣告！',
            btn_agree: '同意',
            btn_disagree: '不同意',

            register_ok: '歡迎您加入tv.SOGA！'
        },
        login: {
            title: '我要登入',
            forgot: '忘記密碼?',
            btn_login: '登入',
            btn_reg: '註冊成為會員',
            ph_statecode: '請選擇區號',
            ph_mobile: '請填寫手機號碼',
            ph_pwd: '請輸入密碼',
            tip_invalid_pwd: '密碼無效！',

            quick_opt: '操作過於頻繁！',
            login_ok: '歡迎您再次回來！',
            login_fail: '登入失敗！'
        },
        resetpwd: {
            title: '重置密碼',
            btn_reset: '重置密碼',

            reset_ok: '密碼重置成功！'
        },
        userinfo: {
            title: '個人資料修改',
            mobile: '手機號碼',
            nick: '暱稱',
            sex: '性別',
            sex_male: '男',
            sex_female: '女',

            height: '身高',
            height_unit: '公分',
            weight: '體重',
            weight_unit: '公斤',

            birth_year: '出生年份',
            ph_birth: '填寫西元年',

            ph_unset: '未設定',
            ph_number: '請填寫數字',

            photo_title: '會員頭像修改',
            btn_photo_upload: '上傳照片'
        },
        setpwd: {
            title: '密碼修改',
            old_pwd: '舊密碼',
            new_pwd: '新密碼',
            cfm_pwd: '確認密碼',
            ph_old_pwd: '請輸入舊密碼',
            ph_new_pwd: '6-15位字母或數字',
            ph_cfm_pwd: '再次輸入設定密碼',
            diff_pwd: '兩次密碼輸入不一致！',
            invalid_pwd: '新密碼無效！',
        },
        massage: {
            title: '按摩放鬆',
            time: '按摩時間',
            strength: '按摩力道',
            pattern: '模式',
            start: '開始按摩',
            stop: '停止按摩',

            pattern_method: '按摩手法',
            pattern_part: '按摩部位',

            pattern1: '按壓',
            pattern2: '揉捏',
            pattern3: '捶打',
            pattern4: '肩部',
            pattern5: '手臂',
            pattern6: '腰部',
            pattern7: '腿部',
            pattern8: '全部',
        },
        knowledge: {
            title: '中醫知識',
            ph_search: '請輸入關鍵字',
            category_all: '全部',
            massage: '進入按摩',
            recommend: '文章推薦'
        },
        user: {
            unlogin: '尚未登入',

            item_userinfo:'個人資料修改',
            item_pwd:'密碼修改',
            item_record:'按摩記錄',
            item_set_up:'應用程序與裝置',
            item_store:'產品購買',
            item_product:'關於產品',
            item_terms:'服務條款',
            item_login:'註冊/登入',
            item_logout:'登出',
        },
        record: {
            no_data: '沒有記錄！',
            pattern: '模式：',
            duration: '時間：',
            last_pattern: '前次按摩模式：',
            last_duration: '前次按摩時間：',
            total_duration: '總時長：'
        }
    }
}
