import { createApp } from 'vue'
import Setting from '@/setting'
import store from '@/store'
// 多语言
import i18n from '@/i18n'
import router from '@/router'
import moment from 'moment'
import omass from '@/omass'
//import ElementPlus from 'element-plus'
//import ep_zhCn from 'element-plus/es/locale/lang/zh-cn'
//import ep_zhTw from 'element-plus/es/locale/lang/zh-tw'
import './styles/common.scss'
import ui from '@/ui'
import App from './App.vue'

const _i18n = i18n.global;
Setting.titleSuffix = _i18n.t('page.title');
Setting.errorModalTitle = _i18n.t('common.alert_title');
// 初始化错误描述字符串
const locale = _i18n.locale;
const errors = _i18n.messages[locale].error;
for (const k in errors) {
    Setting.errorDescriptions[k] = errors[k];
}

Setting.showError = (message, code) => {
    let showMsg = message;
    if (code) {
        showMsg = '[' + code + ']' + message;
    }
    console.error(showMsg);
    // 显示提示，可配置使用 iView 的 $Message 还是 $Notice 组件来显示
    if (Setting.errorModalType === 'Message') {
        ui.Message.error({
          content: showMsg,
          duration: Setting.modalDuration
        })
    } else if (Setting.errorModalType === 'Notice') {
        ui.Notice.error({
          title: Setting.errorModalTitle,
          desc: showMsg,
          duration: Setting.modalDuration
        })
    }
}
/*
const ep_options = {}
if (locale.toLowerCase() == 'zh-cn') {
    ep_options.locale = ep_zhCn;
} else {
    ep_options.locale = ep_zhTw;
}
*/
const app = createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .use(omass)
    .use(ui)
    //.use(ElementPlus, ep_options);
app.config.globalProperties.$dateformat=(dataStr, pattern = 'YYYY-MM-DD HH:mm')=> {
    return moment(dataStr).format(pattern)
};
app.mount('#app');
