import Cookies from 'js-cookie'
import Setting from '@/setting'

const cookies = {}

/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} cookieSetting cookie setting
 */
cookies.set = async function (name = 'default', value = '', cookieSetting = {}) {
    if (null == value) {
        cookies.remove(name);
        return;
    }
    if (!cookieSetting) {
        cookieSetting = {};
    }
    if (!cookieSetting.expires) {
        cookieSetting.expires = Setting.cookiesExpireDays;
    }
    if (typeof cookieSetting.expires === 'number') {
        const date = new Date();
        date.setTime(date.getTime() + cookieSetting.expires * 864e+5);
        cookieSetting.expires = date;
    }
    if (navigator.cookieEnabled) {
        Cookies.set(Setting.cookiesPrefix + '-' + name, value, cookieSetting)
    } else {
        cookieSetting.expires = cookieSetting.expires.getTime();
        cookieSetting.value = value;
        let item = null;
        try {
            item = JSON.stringify(cookieSetting);
        } catch (e) {
            console.error(e);
            return;
        }
        window.localStorage.setItem(Setting.cookiesPrefix + '-' + name, item);
    }
}

const getLocalStorageCookie = (key) => {
    const item = window.localStorage.getItem(key);
    if (item) {
        let cookieSetting = null;
        try {
            cookieSetting = JSON.parse(item);
        } catch (e) {
            console.error(e);
            return null;
        }
        if (cookieSetting.expires < new Date().getTime()) {
            window.localStorage.removeItem(key);
            return null;
        }
        return cookieSetting.value;
    }
    return null;
};

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function (name = 'default') {
  /*if (name === 'token') {
    const bridge = Setting.appBridge();
    if (bridge && bridge.getToken) {
      const token = bridge.getToken();
      if (token) {
        return token;
      }
    }
  }*/
    if (navigator.cookieEnabled) {
        return Cookies.get(Setting.cookiesPrefix + '-' + name)
    } else {
        return getLocalStorageCookie(Setting.cookiesPrefix + '-' + name);
    }
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
    if (navigator.cookieEnabled) {
        return Cookies.get()
    } else {
        const all = {};
        const start = Setting.cookiesPrefix.length;
        const n = window.localStorage.length;
        for (let i = 0; i < n; i++) {
            const key = window.localStorage.key(i);
            if (key.startsWith(Setting.cookiesPrefix)) {
                const value = getLocalStorageCookie(key);
                if (value) {
                    all[key.substring(start)] = value;
                }
            }
        }
    }
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function (name = 'default') {
    if (navigator.cookieEnabled) {
        return Cookies.remove(Setting.cookiesPrefix + '-' + name)
    } else {
        window.localStorage.removeItem(Setting.cookiesPrefix + '-' + name);
        return true;
    }
}

export default cookies
